import { createApi } from '@reduxjs/toolkit/query/react';
import graphqlBaseQuery from '@app/store/api/queries/graphql/baseQuery';
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const baseApiAuthorized = createApi({
    reducerPath: 'authorizedApi',
    baseQuery: graphqlBaseQuery({ baseUrl: apiUrl }),
    tagTypes: [
        'Users',
        'Profiles',
        'ProfileCopiers',
        'ProfileFollowers',
        'ProfileFollowings',
        'Portfolios',
        'PortfolioCopiers',
        'PopularPortfolios',
        'PortfolioPositions',
        'Me',
    ],
    endpoints: () => ({}),
});
export default baseApiAuthorized;
